import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import { useLocation } from 'react-router';
import { obtenir_page_meta } from '../services/pageService';
import PiedPage from '../composants/commun/pied-page';
import FicheProduit from '../composants/services/fiche-produit';
import ReactGA from "react-ga4";

export default function PageService() {

    const [state, setState] = useState({
      metaPage: null
    });
    let location = useLocation();

    useEffect(() => {
        !state.metaPage && obtenir_page_meta(location.pathname, state, setState)
    }, [state, location])

    useEffect(() => {
      if(process.env.REACT_APP_PRODUCTION && state.metaPage && state.metaPage.titre_onglet)
      {
        ReactGA.initialize(process.env.REACT_APP_CODE_SUIVI_ANALYTICS);
        ReactGA.send(
          { 
            hitType: "pageview", 
            page: location.pathname, 
            title: state.metaPage.titre_onglet 
          } 
        );
      }
    }, [state, location])

    return (
      <>
        {
          state.metaPage &&
          <Helmet>
            <title>{state.metaPage.titre_onglet}</title>
            <link rel="canonical" href={process.env.REACT_APP_NOM_DOMAINE + state.metaPage.url_canonique} />
            <meta name="description" content={state.metaPage.description} />
            <link rel="icon" href={process.env.REACT_APP_NOM_DOMAINE_API + "/images/icones/icone-gw.png"}/>
            <meta property="og:locale" content="fr_CA"/>
            <meta property="og:title" content={state.metaPage.titre_onglet}/>
            <meta property="og:description" content={state.metaPage.description}/>
            <meta property="og:url" content={process.env.REACT_APP_NOM_DOMAINE + state.metaPage.url_canonique}/>
            <meta property="og:site_name" content="GreenWood Multimedia"/>
            <meta property="article:modified_time" content={state.metaPage.moment_modification}/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:label1" value="Écrit par"/>
            <meta name="twitter:data1" value="GreenWood Multimedia"/>
          </Helmet>
        }
        <Navigation />
        {
            state.metaPage &&
            <TitrePage>{state.metaPage.nom_page ?? "Page non trouvé"}</TitrePage>
        }
        {
          state.metaPage &&
          <FicheProduit contenu_page={state.metaPage} />
        }
        <PiedPage/>
      </>
    );
  }