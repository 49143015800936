import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router';
import { obtenir_page_meta } from '../services/pageService';
import { verifier_token } from '../services/connexionService';
import ReactGA from "react-ga4";
import FicheFacture from '../composants/voir-facture/fiche-facture';
import LogoSansNav from '../composants/commun/logo-sans-nav';

export default function PageVoirFacture() {

    const [state, setState] = useState({
        metaPage: null
    });
    const location = useLocation();
    const navigate = useNavigate();
    

    useEffect(() => {
      !state.metaPage && obtenir_page_meta(location.pathname, state, setState)
    }, [state, location])

    useEffect(() => {
      verifier_token(navigate, location.pathname)
    }, [location, navigate])

    useEffect(() => {
      if(process.env.REACT_APP_PRODUCTION && state.metaPage && state.metaPage.titre_onglet)
      {
        ReactGA.initialize(process.env.REACT_APP_CODE_SUIVI_ANALYTICS);
        ReactGA.send(
          { 
            hitType: "pageview", 
            page: location.pathname, 
            title: state.metaPage.titre_onglet 
          } 
        );
      }
    }, [state, location])

    return (
      <>
        {
          state.metaPage &&
          <Helmet>
            <title>{state.metaPage.titre_onglet}</title>
            <link rel="canonical" href={process.env.REACT_APP_NOM_DOMAINE + state.metaPage.url_canonique} />
            <meta name="description" content={state.metaPage.description} />
            <link rel="icon" href={process.env.REACT_APP_NOM_DOMAINE_API + "/images/icones/icone-gw.png"}/>
          </Helmet>
        }
        {
            state.metaPage &&
            <>
                <LogoSansNav />
                <FicheFacture />
            </>
        }
      </>
    );
  }