import { Alert, Button, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import styles from "./form-connexion.module.css";
import { useState } from "react";
import { connexion } from "../../services/connexionService";
import { useNavigate } from "react-router";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function FormConnexion() {

    const [state, setState] = useState({
        analyzedUserRecaptcha: false,
        recaptcha_token: "",
        infosConnexion: {
            courriel: "",
            mot_de_passe: ""
        },
        alerteConnexion: {
            type: "",
            texte: ""
        }
    })
    const navigation = useNavigate();

    return (
        <Row className={styles.conteneur_form_connexion + " text-center"}>
            <Col lg="4"></Col>
            <Col lg="4">
                <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            recaptcha_token: recaptcha_token
                        })
                    }
                }} />
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        connexion(state, setState, navigation)
                    }}
                >
                    <div
                        id="alerte_contact"
                    >
                        {
                            state.alerteConnexion.texte &&
                            <Alert variant={state.alerteConnexion.type}>{state.alerteConnexion.texte}</Alert>
                        }
                    </div>
                    <FormGroup>
                        <FormLabel>Votre courriel *</FormLabel>
                        <input 
                            className="form-control"
                            type="text"
                            value={state.infosConnexion.courriel}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosConnexion: {
                                        ...state.infosConnexion,
                                        courriel: event.target.value
                                    },
                                    alerteConnexion: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                            placeholder="johndoe@domaine.com"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Votre mot de passe *</FormLabel>
                        <input 
                            className="form-control"
                            type="password"
                            value={state.infosConnexion.mot_de_passe}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosConnexion: {
                                        ...state.infosConnexion,
                                        mot_de_passe: event.target.value
                                    },
                                    alerteConnexion: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                            placeholder="monMotDePasse"
                        />
                    </FormGroup>
                    <FormGroup className={styles.conteneur_bouton_mot_de_passe_oublier}>
                        <Button 
                            className={styles.bouton_mot_de_passe_oublier}
                        >Mot de passe oublié</Button>
                    </FormGroup>
                    <Button className={styles.bouton_connexion} type="submit">Se connecter</Button>
                </Form>
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}